<template>
  <div
    :class="{
      'col-6 col-sm-4 col-md-3 col-5': propsType.type === 'multi',
      'col-6 col-sm-4 col-md-3': propsType.type === 'multiSearch',
      'd-flex justify-content-center w-100':
        propsType.type === 'single' || propsType.type === 'survey'
    }"
  >
    <div
      class="summary"
      :class="{
        'summary-single': propsType.type === 'single',
        'summary-multi': propsType.type === 'multi',
        'summary-search': propsType.type === 'multiSearch',
        'summary-scroll': propsType.type === 'scroll',
        'summary-scroll-last': propsIndex === 4,
        'summary-scroll-profile': propsType.type === 'scroll-profile',
        'summary-survey': propsType.type === 'survey'
      }"
    >
      <div class="h-100">
        <div
          v-if="image_url || propsUser.image_url"
          @click="nextProfile(propsUser.user_id)"
          class="next"
        >
          <img
            :src="
              propsUser.image_url == image_url ? image_url : propsUser.image_url
            "
            alt=""
            class="summary__img"
          />
        </div>
        <img src="@/assets/image/image-icon.svg" v-else class="icon top" />
      </div>
      <button
        class="btn button d-flex align-items-center justify-content-center"
        @click="like()"
        :class="isLike ? 'button--active' : 'button--inactive'"
        v-if="propsButton"
      >
        <img
          :src="require('@/assets/image/icon_avatar/icon-star-like.svg')"
          class="button__icon"
          alt="like"
          v-if="isLike"
        />
        <img
          :src="require('@/assets/image/icon_avatar/icon-star.svg')"
          class="button__icon"
          alt="like"
          v-else
        />
      </button>
      <div
        class="panel-border position-absolute"
        @click="nextProfile(propsUser.user_id)"
      >
        <div
          v-if="propsScore"
          class="position-relative w-100 h-100 d-flex justify-content-center"
        >
          <div
            class="golf-avatar"
            :class="{
              'golf-avatar-white':
                rank == FEMALE_PREGO_RANK.WHITE ||
                rank == MALE_PREGO_RANK.WHITE ||
                rank == null,
              'golf-avatar-red':
                rank == FEMALE_PREGO_RANK.RED || rank == MALE_PREGO_RANK.RED,
              'golf-avatar-blue':
                rank == FEMALE_PREGO_RANK.BLUE || rank == MALE_PREGO_RANK.BLUE,
              'golf-avatar-green':
                rank == FEMALE_PREGO_RANK.GREEN ||
                rank == MALE_PREGO_RANK.GREEN,
              'golf-avatar-silver':
                rank == FEMALE_PREGO_RANK.SILVER ||
                rank == MALE_PREGO_RANK.SILVER,
              'golf-avatar-gold':
                rank == FEMALE_PREGO_RANK.GOLD || rank == MALE_PREGO_RANK.GOLD,
              'golf-avatar-black':
                rank == FEMALE_PREGO_RANK.BLACK ||
                rank == MALE_PREGO_RANK.BLACK ||
                rank == 'PG'
            }"
          />
          <p class="position-absolute best_score f-score">
            {{ propsUser.best_score >= 160 ? 160 : propsUser.best_score }}
          </p>
          <p
            class="position-absolute avg_score f-score"
            :class="
              rank == FEMALE_PREGO_RANK.WHITE ||
              rank == MALE_PREGO_RANK.WHITE ||
              rank == FEMALE_PREGO_RANK.SILVER ||
              rank == MALE_PREGO_RANK.SILVER ||
              rank == null
                ? 'avg_score-white'
                : ''
            "
          >
            {{ propsUser.avg_score >= 180 ? 180 : propsUser.avg_score }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import { MALE_PREGO_RANK, FEMALE_PREGO_RANK } from "@/utils/const";
export default {
  name: "UserSummary",
  props: {
    propsUser: {
      type: Object,
      default: null
    },
    propsScore: {
      type: Boolean,
      default: true
    },
    propsType: {
      type: Object,
      default: null
    },
    propsIndex: {
      type: Number,
      default: 1
    },
    propsSex: {
      type: Number,
      default: null
    },
    propsButton: {
      type: Boolean,
      default: true
    },
    propsShadow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isJapanFont: false,
      rank: null,
      FEMALE_PREGO_RANK: FEMALE_PREGO_RANK,
      MALE_PREGO_RANK: MALE_PREGO_RANK
    };
  },
  computed: {
    ...mapGetters({ image_url: "userApp/image_url" }),
    isLike() {
      if (this.propsUser.is_favorite == 1) {
        return true;
      }

      return false;
    }
  },
  created() {
    if (this.propsSex == 2) {
      switch (this.propsUser.female_prego_rank_id) {
        case 1:
          this.rank = FEMALE_PREGO_RANK.WHITE;
          break;
        case 2:
          this.rank = FEMALE_PREGO_RANK.GREEN;
          break;
        case 3:
          this.rank = FEMALE_PREGO_RANK.BLUE;
          break;
        case 4:
          this.rank = FEMALE_PREGO_RANK.RED;
          break;
        case 5:
          this.rank = FEMALE_PREGO_RANK.SILVER;
          break;
        case 6:
          this.rank = FEMALE_PREGO_RANK.GOLD;
          break;
        case 7:
          this.rank = FEMALE_PREGO_RANK.BLACK;
          break;
        case 8:
          this.rank = FEMALE_PREGO_RANK.PG;
          break;
        default:
          this.rank = this.propsUser.female_prego_rank;
      }
    } else {
      switch (this.propsUser.male_prego_rank_id) {
        case 1:
          this.rank = MALE_PREGO_RANK.WHITE;
          break;
        case 2:
          this.rank = MALE_PREGO_RANK.GREEN;
          break;
        case 3:
          this.rank = MALE_PREGO_RANK.BLUE;
          break;
        case 4:
          this.rank = MALE_PREGO_RANK.RED;
          break;
        case 5:
          this.rank = MALE_PREGO_RANK.SILVER;
          break;
        case 6:
          this.rank = MALE_PREGO_RANK.GOLD;
          break;
        case 7:
          this.rank = MALE_PREGO_RANK.BLACK;
          break;
        case 8:
          this.rank = MALE_PREGO_RANK.PG;
          break;
        default:
          this.rank = this.propsUser.male_prego_rank;
      }
    }
  },

  methods: {
    like() {
      this.$emit("update-like", {
        userId: this.propsUser.user_id,
        isFavorite: this.propsUser.is_favorite
      });
    },
    nextProfile(id) {
      const user = store.getters["auth/user"];
      if (this.propsType.type != "single") {
        if (user.sex == 1) {
          this.$root.$refs.loading.start();
          this.$router.push({ name: "CastProfile", params: { id: id } });
          this.$emit("getUser", id);
          this.$root.$refs.loading.finish();
        } else {
          this.$root.$refs.loading.start();
          this.$router.push({ name: "MenProfile", params: { id: id } });
          this.$emit("getUser", id);
          this.$root.$refs.loading.finish();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/userSummary.scss";
@import "../assets/scss/_fontFamily.scss";
</style>
