var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'col-6 col-sm-4 col-md-3 col-5': _vm.propsType.type === 'multi',
    'col-6 col-sm-4 col-md-3': _vm.propsType.type === 'multiSearch',
    'd-flex justify-content-center w-100':
      _vm.propsType.type === 'single' || _vm.propsType.type === 'survey'
  }},[_c('div',{staticClass:"summary",class:{
      'summary-single': _vm.propsType.type === 'single',
      'summary-multi': _vm.propsType.type === 'multi',
      'summary-search': _vm.propsType.type === 'multiSearch',
      'summary-scroll': _vm.propsType.type === 'scroll',
      'summary-scroll-last': _vm.propsIndex === 4,
      'summary-scroll-profile': _vm.propsType.type === 'scroll-profile',
      'summary-survey': _vm.propsType.type === 'survey'
    }},[_c('div',{staticClass:"h-100"},[(_vm.image_url || _vm.propsUser.image_url)?_c('div',{staticClass:"next",on:{"click":function($event){return _vm.nextProfile(_vm.propsUser.user_id)}}},[_c('img',{staticClass:"summary__img",attrs:{"src":_vm.propsUser.image_url == _vm.image_url ? _vm.image_url : _vm.propsUser.image_url,"alt":""}})]):_c('img',{staticClass:"icon top",attrs:{"src":require("@/assets/image/image-icon.svg")}})]),(_vm.propsButton)?_c('button',{staticClass:"btn button d-flex align-items-center justify-content-center",class:_vm.isLike ? 'button--active' : 'button--inactive',on:{"click":function($event){return _vm.like()}}},[(_vm.isLike)?_c('img',{staticClass:"button__icon",attrs:{"src":require('@/assets/image/icon_avatar/icon-star-like.svg'),"alt":"like"}}):_c('img',{staticClass:"button__icon",attrs:{"src":require('@/assets/image/icon_avatar/icon-star.svg'),"alt":"like"}})]):_vm._e(),_c('div',{staticClass:"panel-border position-absolute",on:{"click":function($event){return _vm.nextProfile(_vm.propsUser.user_id)}}},[(_vm.propsScore)?_c('div',{staticClass:"position-relative w-100 h-100 d-flex justify-content-center"},[_c('div',{staticClass:"golf-avatar",class:{
            'golf-avatar-white':
              _vm.rank == _vm.FEMALE_PREGO_RANK.WHITE ||
              _vm.rank == _vm.MALE_PREGO_RANK.WHITE ||
              _vm.rank == null,
            'golf-avatar-red':
              _vm.rank == _vm.FEMALE_PREGO_RANK.RED || _vm.rank == _vm.MALE_PREGO_RANK.RED,
            'golf-avatar-blue':
              _vm.rank == _vm.FEMALE_PREGO_RANK.BLUE || _vm.rank == _vm.MALE_PREGO_RANK.BLUE,
            'golf-avatar-green':
              _vm.rank == _vm.FEMALE_PREGO_RANK.GREEN ||
              _vm.rank == _vm.MALE_PREGO_RANK.GREEN,
            'golf-avatar-silver':
              _vm.rank == _vm.FEMALE_PREGO_RANK.SILVER ||
              _vm.rank == _vm.MALE_PREGO_RANK.SILVER,
            'golf-avatar-gold':
              _vm.rank == _vm.FEMALE_PREGO_RANK.GOLD || _vm.rank == _vm.MALE_PREGO_RANK.GOLD,
            'golf-avatar-black':
              _vm.rank == _vm.FEMALE_PREGO_RANK.BLACK ||
              _vm.rank == _vm.MALE_PREGO_RANK.BLACK ||
              _vm.rank == 'PG'
          }}),_c('p',{staticClass:"position-absolute best_score f-score"},[_vm._v(" "+_vm._s(_vm.propsUser.best_score >= 160 ? 160 : _vm.propsUser.best_score)+" ")]),_c('p',{staticClass:"position-absolute avg_score f-score",class:_vm.rank == _vm.FEMALE_PREGO_RANK.WHITE ||
            _vm.rank == _vm.MALE_PREGO_RANK.WHITE ||
            _vm.rank == _vm.FEMALE_PREGO_RANK.SILVER ||
            _vm.rank == _vm.MALE_PREGO_RANK.SILVER ||
            _vm.rank == null
              ? 'avg_score-white'
              : ''},[_vm._v(" "+_vm._s(_vm.propsUser.avg_score >= 180 ? 180 : _vm.propsUser.avg_score)+" ")])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }