<template>
  <div class="app-modal" :class="{ active: show }">
    <transition name="slide-down">
      <div v-if="show" class="app-modal__dialog">
        <div class="d-flex justify-content-center" v-if="header">
          <div
            class="d-flex justify-content-center align-items-center app-modal__dialog__header"
          >
            <button class="btn app-modal__close" @click="closeModal()">
              <img
                :src="require('@/assets/image/close.svg')"
                alt="close popup"
              />
            </button>
            <p class="f-w6 app-modal__tilte">{{ name }}</p>
          </div>
        </div>
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ModalLayout",
  props: {
    name: String,
    header: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      // document.querySelector("body").style.cssText =
      //   "height: unset; overflow-y: unset";
    },
    openModal() {
      this.show = true;
      // document.querySelector("body").style.cssText =
      //   "height: 100vh; overflow-y: hidden";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_fontFamily.scss";
@import "@/assets/scss/_variables.scss";

.app-modal {
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 9000;
  max-width: 1080px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  left: 50%;
  transform: translateX(-50%);
  &__close {
    outline: none;
    box-shadow: none;
  }
  &.active {
    visibility: visible;
  }

  &__dialog {
    overflow: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    .footer-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      max-width: 337px;
      height: 75px;
      position: fixed;
      z-index: 10;
      bottom: 20px;
      left: 0;
      right: 0;
      .btn-submit {
        width: 100%;
        max-width: 335px;
        height: 45px;
        background-color: $orange;
        border-radius: 23px;
        color: $white;
        position: relative;
        z-index: 3;
      }
      .blur-filter {
        position: absolute;
        top: 45px;
        z-index: 1;
        background-color: #ecbf9c;
        height: 22px;
        width: 221px;
        filter: blur(5px);
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
    & {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
    }
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    &__header {
      padding: 9px 0;
      // position: fixed;
      top: 0;
      // left: 50%;
      // transform: translateX(-50%);
      max-width: 1080px;
      width: 100%;
      background-color: #ffffff;
      z-index: 100;
      border-bottom: 1px solid #d9d9d9;
    }
    &--content {
      background-color: #ffffff;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      max-width: 1080px;
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      & {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
      }
    }
  }

  .app-modal__tilte {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
    color: #000000;
  }
  .app-modal__close {
    position: absolute;
    left: 20px;
    padding: 0;
    img {
      width: 14px;
      display: block;
    }
  }
}

.slide-down {
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(-20px);
  }

  &-enter-active,
  &-leave-active {
    transition: ease 0.5s;
  }

  &-enter-to,
  &-leave {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (min-width: 1080px) {
  .app-modal {
    &__dialog {
      &__header {
        padding: 15px 0;
        // position: fixed;
        top: 0;
        width: 100%;
        background-color: #ffffff;
        z-index: 100;
        border-bottom: 1px solid #d9d9d9;
      }
      .app-modal__tilte {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 0;
        color: #000000;
      }
    }
    .app-modal__close {
      position: absolute;
      left: 35px;
      padding: 0;
      img {
        width: 18px;
        display: block;
      }
    }
  }
}
</style>
