<template>
  <div
    class="offer-select"
    :tabindex="tabindex"
    @click="show = !show"
    @blur="show = false"
    :class="show ? 'active' : ''"
  >
    <div class="offer-select__div">
      <div
        class="offer-select__value d-flex justify-content-start align-items-center flex-wrap"
      >
        <div class="_item" v-for="(item, key) of Seleted" :key="key">
          {{ item.name }}

          <div class="btn-remove-option" @click="remoteOption(key)"></div>
        </div>
      </div>
    </div>
    <div class="offer-select__options">
      <div
        class="offer-select__item d-flex justify-content-between align-items-center"
        v-for="(item, key) of options"
        :key="key"
        @click="chooseOption(key)"
        :class="value.indexOf(key) > -1 ? 'active' : ''"
      >
        {{ item.name }}
        <!-- <b-icon
          icon="check"
          class="offer-select__icon"
          :class="value.indexOf(key) > -1 ? 'active' : ''"
          scale="1.5"
        ></b-icon> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultiSelectFilter",
  props: {
    options: {
      type: Array,
      require: true
    },
    value: {
      type: Array
    },
    tabindex: {
      type: Number,
      require: null,
      default: 0
    }
  },
  computed: {
    Seleted: function() {
      let selected = [];
      this.value.map(item => {
        selected.push(this.options[item]);
      });

      return selected;
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    chooseOption(key) {
      this.show = false;
      if (this.value.indexOf(key) === -1) {
        this.value.push(key);
      }
      this.$emit("select-option", this.value);
    },
    remoteOption(key) {
      this.show = !this.show;
      this.value.splice(key, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  opacity: 0.1;
}
.offer-select {
  height: 40px;
  outline: none;
  width: 100%;
  border-radius: 5px;
  position: relative;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  user-select: none;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    right: 17px;
    top: calc(50% - 3px);
    border-top: 6px solid #9c9c9c;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    transition: ease 0.5s;
  }
  .drop-down {
    height: 100%;
    width: 40px;
    position: absolute;
    top: 0;
    right: 0;
    &::after {
      content: "";
      position: absolute;
      right: 17px;
      top: calc(50% - 3px);
      border-top: 6px solid #9c9c9c;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      transition: ease 0.5s;
    }
  }
  &.active {
    .offer-select__options {
      opacity: 1;
      transform: scaleY(1);
    }
    &::after {
      transform: rotate(180deg);
    }

    .drop-down {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &__div {
    height: 40px;
    width: 90%;
    font-family: "Hiragino Kaku Gothic Pro W3";
    text-align: left;
    // border-left: 9px solid #ffffff;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 3px;
    font-size: 14px;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      height: 3px;
    }
    // &::-webkit-scrollbar-track {
    //   border-radius: 5px;
    // }
    // /* Optional: show position indicator in red */
    // &::-webkit-scrollbar-thumb {
    //   background: #dcdcdc;
    // }
    // &::-webkit-scrollbar-thumb:hover {
    //   background: #00000029;
    // }
    overflow-y: auto;
  }
  &__value {
    width: 100%;
    ._item {
      padding: 3px 20px 3px 15px;
      background-color: #e5e5e5;
      color: #000000;
      font-size: 12px;
      border-radius: 5px;
      margin-right: 3px;
      height: fit-content;
      position: relative;
      margin-bottom: 3px;
      white-space: nowrap;

      .btn-remove-option {
        width: 8px;
        height: 8px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8px;

        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 10px;
          height: 2px;
          border-radius: 2px;
          background-color: #000000;
          top: 50%;
          left: 50%;
        }

        &::before {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
        &::after {
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }
  }

  &__options {
    position: relative;
    z-index: 2;
    opacity: 0;
    width: 100%;
    transition: ease 0.3s;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    transform-origin: top;
    transform: scaleY(0);
    box-shadow: 0 0 15px #00000029;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    font-size: 14px;
    font-family: "Hiragino Kaku Gothic Pro W3";
    &.active {
      background-color: #e5e5e5;
    }
  }
  &__item:hover {
    background-color: #e5e5e5;
  }

  &__icon {
    color: #0178fd;
    display: none;
    &.active {
      display: block;
    }
  }
}
@media screen and (min-width: 992px) {
  .offer-select {
    height: 45px;
    &__div {
      height: 45px;
      padding: 7px 20px 7px 10px;
    }
    &__value {
      ._item {
        font-size: 14px;
      }
    }
  }
}
</style>
